import { callAPIGet } from "../api";

export async function fetchDefaultCityRedirect() {
    return callAPIGet("/api/v1/pages/home/default-city-redirect/");
}

export async function fetchHomePageProps(city, date, place, game_type, game_level, cookies) {
    return callAPIGet("/api/v1/pages/home/", { city, date, place, game_type, game_level }, { cookies });
}

export async function fetchGamesPageProps(city, category) {
    const response = await callAPIGet("/api/v1/pages/games/", { city , category});
    return response.json();
}

export async function fetchGameDescriptionPageProps(city, id, cookies) {
    return await callAPIGet(`/api/v1/pages/games/${id}/`, { city }, { cookies });
}

export async function fetchLandingPageProps(slug, cookies) {
    return callAPIGet("/api/v1/pages/landing/", { slug }, { cookies });
}

export async function fetchHostsByGameAndHostLevel(game_type, host_level) {
    const response = await callAPIGet("/api/v1/landing/hosts/", { game_type, host_level });
    return response.json();
}

export async function fetchGameDescriptions(city, category) {
    const response = await callAPIGet("/api/v1/gamedescription/", { city, category });
    return response.json();
}

export async function fetchContactsPageProps(city) {
    const response = await callAPIGet("/api/v1/pages/contacts/", { city });
    return response.json();
}

export async function fetchStaticPage(url, cookies) {
    const response = await callAPIGet("/api/v1/staticpage/", { url }, { cookies });
    return response.json();
}

export async function fetchAnnouncements() {
    const response = await callAPIGet(`/api/v1/announcements/`);
    return response.json();
}

export async function fetchFaq(cookies) {
    const response = await callAPIGet("/api/v1/faq/", {}, { cookies });
    return response.json();
}

export async function fetchHostFaq(cookies) {
    const response = await callAPIGet("/api/v1/faq/host/", {}, { cookies });
    return response.json();
}

export async function fetchManagerFaq(cookies) {
    const response = await callAPIGet("/api/v1/faq/manager/", {}, { cookies });
    return response.json();
}

export async function fetchMafiaFaq(cookies) {
    const response = await callAPIGet("/api/v1/faq/mafia", {}, { cookies });
    return response.json();
}

export async function fetchSnippets(slugs) {
    const response = await callAPIGet("/api/v1/snippet/", { slugs });
    return response.json();
}
